import React, { useRef, useImperativeHandle } from 'react';
import {
  getDataAttributes,
  keyCodes,
  formatClassNames,
} from '@wix/editor-elements-common-utils';
import { useDidUpdate } from '@wix/thunderbolt-elements/providers/useDidUpdate';
import { useMSBStates } from '../../MultiStateBox/viewer/useMSBStates';
import type {
  ITabsProps,
  ITabsImperativeActions,
  ITabsListItem,
} from '../Tabs.types';
import { TabItemClickedEventType } from '../constants';
import semanticClassNames from '../Tabs.semanticClassNames';
import ResponsiveBox from '../../ResponsiveBox/viewer/ResponsiveBox';
import TabsList from './TabsList/TabsList';
import { st, classes } from './style/Tabs.component.st.css';
import TabWrapper from './TabWrapper';

const getDefaultContainerProps = (id: string) => ({
  // TODO - wire correctly
  containerLayoutClassName: `${id}-container`,
  // TODO - wire correctly
  overlowWrapperClassName: `${id}-overflow-wrapper`,
  // TODO - wire correctly
  hasOverflow: true,
});

const getTabId = (
  tabItems: Array<ITabsListItem>,
  tabElement: React.ReactElement,
) => {
  const tabItem = tabItems.find(item => item.id === tabElement.props.id);
  return tabItem?.tabId ?? '';
};

const Tabs: React.ForwardRefRenderFunction<
  ITabsImperativeActions,
  ITabsProps
> = (props, ref) => {
  const {
    id,
    currentTabId,
    tabItems,
    className,
    customClassNames = [],
    stylableClassName,
    children,
    setCurrentTabId,
    onTabItemClicked,
    onChange,
    onClick,
    onDblClick,
    onMouseEnter,
    onMouseLeave,
    hasResponsiveLayout,
    containerProps,
    containerRootClassName,
    observeChildListChange,
    shouldFixResponsiveBoxContainerLayoutClassName,
  } = props;

  const interactionProps = {
    onClick,
    onDoubleClick: onDblClick,
    onMouseEnter,
    onMouseLeave,
  };

  useImperativeHandle(ref, () => ({
    setCurrentTabId,
  }));

  const tabRefs = useRef<Record<string, HTMLDivElement>>({});
  // focuses the tab after is visible
  useDidUpdate(() => {
    tabRefs.current[currentTabId]?.focus({ preventScroll: true });
  }, [currentTabId]);

  const wrap = (tabElement: React.ReactElement) => {
    return TabWrapper({
      tabElement,
      currentTabRef: (tabRef: HTMLDivElement) => {
        tabRefs.current[getTabId(tabItems, tabElement)] = tabRef;
      },
      isCurrentTab: getTabId(tabItems, tabElement) === currentTabId,
    });
  };

  const tabsToRender = useMSBStates({
    children,
    getStateId: (tabElement: React.ReactElement) =>
      getTabId(tabItems, tabElement),
    selectedStateId: currentTabId,
    onChange,
    shouldRenderAllTabs: true,
    wrap,
  });

  const activeMenuItemRef = useRef<HTMLDivElement>(null);

  const tabsContainerRef = useRef<HTMLDivElement>(null);

  const handleTabItemClick = (tabId: string, uniqueId: string) => {
    setCurrentTabId(tabId);
    onTabItemClicked?.({ type: TabItemClickedEventType, tabId: uniqueId });
  };

  const handleKeyboardNav = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === keyCodes.escape) {
      activeMenuItemRef.current?.focus({ preventScroll: true });
    }
  };

  React.useEffect(() => {
    if (observeChildListChange && tabsContainerRef?.current) {
      observeChildListChange(id, tabsContainerRef.current as HTMLElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = (
    <div
      className={st(
        classes.root,
        stylableClassName,
        formatClassNames(semanticClassNames.root, ...customClassNames),
      )}
    >
      <TabsList
        className={classes.tabsList}
        currentTabId={currentTabId}
        tabItems={tabItems}
        onTabItemClick={handleTabItemClick}
        activeMenuItemRef={activeMenuItemRef}
      />
      <div
        className={st(
          classes.multiStateBoxWrapper,
          formatClassNames(semanticClassNames.tabContainer),
        )}
        onKeyDown={handleKeyboardNav}
        ref={tabsContainerRef}
      >
        {tabsToRender}
      </div>
    </div>
  );

  return hasResponsiveLayout ? (
    <ResponsiveBox
      {...getDataAttributes(props)}
      {...interactionProps}
      className={className}
      hasPlatformClickHandler={false}
      containerRootClassName={containerRootClassName}
      containerProps={containerProps || getDefaultContainerProps(id)}
      id={id}
      shouldUseContainerLayoutClass={
        shouldFixResponsiveBoxContainerLayoutClassName
      }
    >
      {() => content}
    </ResponsiveBox>
  ) : (
    <div
      {...getDataAttributes(props)}
      {...interactionProps}
      className={className}
      id={id}
    >
      {content}
    </div>
  );
};

export default React.forwardRef(Tabs);
